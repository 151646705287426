<template>
  <div
    v-if="data.fields"
    class="separator-wrapper"
    :class="data.fields.mobileDesktop"
  >
    <div class="separator"></div>
  </div>
</template>

<script>

export default {
  name: 'Separator',

  transition: 'page',

  props: [ 'data' ]

}
</script>

<style scoped lang="scss">
.separator-wrapper {
  margin: auto;
  padding: 1em 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.separator {
  width: 50%;
  height: 1px;
  background: $color-wrapper-emphasis-lighter;
  @include breakpoint($tablet) {
    width: 35%;
  }
}
.Mobile {
  @include breakpoint($tablet) {
    display: none;
  }
}
.Desktop {
  display: none;
  @include breakpoint($tablet) {
    display: block;
  }
}
</style>
